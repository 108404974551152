import React from "react";
import AboutUsPage from "./AboutUsPage/AboutUsPage";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

export default () => (
  <AboutUsPage/>
);
